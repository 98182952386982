import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';
import StringUtils from '../utils/StringUtils';
import Browser from '../utils/Browser';

class PrintView extends React.Component {
  constructor(props) {
    super(props);

    const { lang } = this.props;
    
    this.lang = lang;
    this.i18n = new I18n({lang});

    this.state = {
      displayResult: false,
      result: []
    };
  }

  componentDidMount () {
    this.queryCertificationsData();
  }

  queryCertificationsData = async () => {
    this.setState({
      displayResult: false,
      result: []
    });

    const searchStr = Browser.getParameterByName('searchString');

    await axios.get( 
      process.env.GATSBY_DRM_ENDPOINT, {
        params: {
          searchString: searchStr,
          languageLocaleKey: this.lang
        }
      }
    ).then( result => {
      let resultMsg;
      let data = [];

      if( result.data.status == 'success' ) {
        const serverResponse = JSON.parse(result.data.data[0].jsonResponse);
        data = serverResponse.data;
        //scrollToElement('[data-results-container]', -200);
      }

      this.setState({
        displayResult: true,
        result: data
      });

    }).catch(error => {
      this.setState({
        displayResult: true,
        result: data
      });
    });
  };


  render() {

    const {displayResult, result} = this.state;

    return (
      <>
        {!displayResult && (
          <div className="cs-spinner-container">
            <tds-spinner></tds-spinner>
          </div>
        )}
        <div className="slds-container--large slds-p-around--medium">
          {displayResult && (
            result.length == 0 ? (
              <div className="slds-text-align--center slds-p-horizontal--xx-large Fz(20)">
                {this.i18n.text('verification_print_view_no_results')}
              </div>
            ) : (
              result.map((data, i) => {
                return (
                  <div key={`data-${i}`}>
                    <div className="slds-grid">
                      <div className="slds-size--1-of-2 slds-p-top--medium slds-p-bottom--large Fz(20) Fw(b)">
                        {data.Name}
                      </div>
                    </div>
                    <div className="slds-m-top--x-medium">
                      <div className="slds-p-horizontal--x-large">
                        <div className="clearfix slds-p-horizontal--small">
                          <div className="F(l) Fz(16) Fw(b)">{this.i18n.text('verification_title_credential')}</div>
                          <div className="F(r) Fz(16) Fw(b)">{this.i18n.text('verification_title_earned_date')}</div>
                        </div>
                        <hr className="gray-dotted"/>
                        
                        <div className="verification-overlay__items-container">
                          {data.RelatedCertificationStatus.records.map((record, i2) => {
                            const imageName = StringUtils.camelize(record.ExternalCertificationTypeName);
                            const amzn_imageUrl = 'https://developer.salesforce.com/resources2/certification-site/images/credentials/' + imageName + '.jpg';
                            const  imageUrl = record.hasOwnProperty('RelatedCertificationType') && record.RelatedCertificationType.hasOwnProperty('Image') ? record.RelatedCertificationType.Image : amzn_imageUrl;
                    
                            return (
                              <div key={`record-${i2}`}>
                                <div className="slds-grid slds-p-vertical--medium slds-grid--vertical-align-center slds-p-horizontal--medium">
                                  <div className="F(l) slds-m-right--small W(100px)">
                                    <img src={imageUrl}/>
                                  </div>
                                  <div className="slds-col slds-p-horizontal--medium">{record.ExternalCertificationTypeName}</div>
                                  <div className="W(180px)">{record.CertificationDate}</div>
                                </div>
                                <hr className="gray-dotted"/>
                              </div>
                            )
                          })}
                        </div>

                        <div 
                          className="slds-p-vertical--large slds-text-align--center slds-p-horizontal--medium" 
                          dangerouslySetInnerHTML={{__html: this.i18n.text('verification_title_footer_text')}} />
                      </div>
                    </div>
                  </div>
                )
              })
            )
          )}
        </div>
      </>
    );
  }
}

PrintView.propTypes = {
  lang: PropTypes.string.isRequired
};

export default PrintView;
